import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { AppProps } from 'next/dist/next-server/lib/router/router'
import { Component } from 'react'
import '../styles/globals.scss'

export default class MyApp extends Component<AppProps> {
  componentDidMount() {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: process.env.SENTRY_DSN,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }

  componentDidCatch(error) {
    Sentry.captureException(error)
  }

  render() {
    let { Component, pageProps } = this.props

    return <Component {...pageProps} />
  }
}
